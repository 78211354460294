import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import Message from '../components/Message';
import ScoresScreenTeam from '../common-libs/quiz/screens/ScoresScreenTeam';

function FinalLeaderboardTeamPage() {
	
	const {quizId} = useParams();
	
	const [query] = useSearchParams();
	const cssClass = query.get('cssClass') || '';
	
	const [state, setState] = useState({message: null});
	
	const onLeaderboardChange = data => {
		setState(state => {
			return {...state, scores: data.teams};
		});
	}
	
	/**
	 * Load the scores.
	 */
	useEffect(() => {
		let active = true;
		let socket;
		
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/teams`;
				const response = await fetch(url);
				const quiz = await response.json();
				
				if (!active) {
					return;
				} else if (quiz.error) {
					return setState({message: quiz.error});
				}
				setState({quiz, scores: quiz.leaderboard});
				
			} catch (e) {
				console.log(e);
				return setState({message: 'Something went wrong. Please try again later.'})
			}
		})();
		
		return () => {
			active = false;
			if (socket) {
				socket.off('leaderboard:change', onLeaderboardChange);
				socket.disconnect();
			}
		}
		
	}, [quizId]);
	
	return (
		<div className={`leaderboard-team-page ${cssClass}`}>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{state.quiz && <ScoresScreenTeam
				teams={state.quiz.teams}
				scores={state.scores}
				title={state.quiz.screens.scores.title}
				styles={{
					title: state.quiz.screens.scores.screenTitle,
					page: state.quiz.screens.scores.success
				}}
			/>}
		</div>
	)
}

export default FinalLeaderboardTeamPage;
